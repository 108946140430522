import { eventBus } from "@/eventBus";
import { store } from "@/store/store";

export function observerInterSection() {
    var observer = new IntersectionObserver(onIntersect);
    document.querySelectorAll("[data-lazy-load]").forEach(function (img) {
      observer.observe(img);
    });
    function onIntersect(entries, observer) {
      entries.forEach(function (entry) {
        if (entry.target.getAttribute("data-processed") || !entry.isIntersecting)
          return true;
        entry.target.setAttribute("src", entry.target.getAttribute("data-src"));
        entry.target.setAttribute("data-processed", true);
      });
    }
}

